/* Стили для страницы с аудиоплеером */
.player-page {
  background-image: url('background.PNG');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  padding: 20px;
  box-sizing: border-box;
}

.player-container {
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
}

/* Стили для аудиоплеера */
.audio-player {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: rgba(249, 249, 249, 0.9);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Высококонтрастный режим */
.high-contrast .play-pause-button {
  background-color: yellow;
  color: black;
  border: 2px solid black;
}

.high-contrast .audio-player {
  background-color: black;
  color: white;
  border-color: white;
}

.controls {
  display: flex;
  justify-content: center;
}

.play-pause-button {
  font-size: 3em;
  padding: 30px 70px;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 60px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s ease;
}

.play-pause-button:focus,
.play-pause-button:hover {
  background-color: #333;
}

@media (min-width: 768px) {
  .play-pause-button {
    font-size: 3.5em;
    padding: 40px 80px;
  }
}